<template>
  <div class="h-screen flex w-full vx-row no-gutter items-center justify-center" >
    <div class="vx-col w-1/4 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col w-full d-theme-dark-bg">
              <div class="p-8">

                <div class="flex items-center mb-4 ">
                  <h4 class="text-center">SETUP PLATAFORMA</h4>                   
                  <img :src="getLogoCentral" alt="login" class="mx-auto" style="width: 25%; height: 25%">
                          
                </div>

                <div class="p-2">
                  <vs-input
                      name="login"
                      icon-no-border
                      icon="icon icon-user"
                      icon-pack="feather"
                      label-placeholder="Login"
                      v-model="login"
                      @keyup.enter="efetuarLogin"
                      class="w-full"/>

                  <vs-input
                      type="password"
                      name="senha"
                      icon-no-border
                      icon="icon icon-lock"
                      icon-pack="feather"
                      label-placeholder="Senha"
                      v-model="senha"
                      @keyup.enter="efetuarLogin"
                      class="w-full mt-6" />

                  <div class="flex flex-wrap justify-between my-5 items-center">
                      <vs-button @click="efetuarLogin">Login</vs-button>
                      <p class="ml-auto text-xs font-medium"> {{VERSAO}} </p>         
                  </div>
                  
                </div>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    nomeCentral: {
      type: String      
    }
  }, 
  data() {
    return {
      login: "",
      senha: "",
      checkbox_remember_me: false,
      vNomeCentral: 'CONCEPT',
      publicPath: process.env.BASE_URL,
    }
  },
  computed: {
    getLogo() {
      return require(`@/assets/images/logo/logoCONCEPT.png`)
    },
    getLogoCentral() {
      let nomeCentral = this.getNomeCentral();
      return this.publicPath + "imagens/logoCENTRAIS/logo" + nomeCentral.toUpperCase() +".png";
    },
  },
  created(){
      this.VERSAO = process.env.VUE_APP_NUMERO_VERSAO
  },
  mounted() {
    let nomeCentral = this.getNomeCentral();
    if (nomeCentral) {               
       this.vNomeCentral = nomeCentral;
       localStorage.setItem('nomeCentral', nomeCentral);
    } else {
      if (this.$store.state.nomeCentral) {
         this.vNomeCentral = this.$store.state.nomeCentral;                  
         if (!localStorage.getItem('nomeCentral') || localStorage.getItem('nomeCentral') === 'undefined') {
           localStorage.nomeCentral = this.$store.state.nomeCentral;
         }
      } else {
        if (localStorage.getItem('nomeCentral') && localStorage.getItem('nomeCentral') !== 'undefined') {          
          this.vNomeCentral = localStorage.nomeCentral;     
        } else {          
          localStorage.setItem('nomeCentral', this.vNomeCentral);
        }
      }
    }
    
    if (localStorage.login) {
      this.login = localStorage.login;
    }
  },
  methods: {
      getNomeCentral() {
        var endereco = window.location.hostname;
        let nomeCentral = "CONCEPT"          
        if (endereco == 'setup.santrack.com.br') {
            nomeCentral = "SANTRACK";
        }

        return nomeCentral;
      },
      efetuarLogin() {      
        const params = new URLSearchParams();
        params.append('outputFormat', 'JSON');
        params.append('login', this.login);
        params.append('senha', this.senha);
                
        this.$vs.loading();

        let currentObj = this;
        
        this.$http.post('/ValidarLoginSenhaBackend', 
            params, { headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'}
        }).then(function (response) {
            currentObj.$vs.loading.close();
            
            if (response.data.id && response.data.id !== 0) {   
                localStorage.login = currentObj.login;
                currentObj.$store.commit("SALVAR_USUARIO_LOGADO", response.data);  
                if (currentObj.$store.state.AppActiveUser.perfil === 'MONITOR_PANICO' || currentObj.$store.state.AppActiveUser.perfil === 'EMPRESA_MONITOR_PANICO') { 
                  currentObj.$router.push({ path: '/backend/monitoramento' })
                }  else {
                  currentObj.$router.push({ path: '/home' })
                }  
                
            } else {                	            
              currentObj.$vs.notify({
                title: "Erro",
                text: "Acesso Inválido",
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              });
            }
            
        }).catch(function () {
            currentObj.$vs.loading.close();
            
            currentObj.$vs.notify({
                    title: "Erro",
                    text: "Erro no acesso",
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                  });
        });

      }
  }  
}
</script>